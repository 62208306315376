import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Header from "./components/header";
import { Forloadandnavigate } from "./functions/useNaviLoadBoth";
import RegistrationPage from "./pages/home";
import RegistrationSuccessPage from "./pages/success";
import { Toaster } from "sonner";
import ContentLayout from "./layout";
import MissingPage from "./pages/missing";

function App() {
  return (
    <div className="flex flex-row flex-1 h-dvh font-inter">
      <Routes>
        <Route path="/" element={<ContentLayout />}>
          <Route path="/" element={<RegistrationPage />} />
          <Route path="/success" element={<RegistrationSuccessPage />} />
          <Route path="*" element={<MissingPage />} />
        </Route>
      </Routes>
      <Header />
      <Forloadandnavigate />
      <Toaster richColors={true} expand={true} theme={"system"} />
    </div>
  );
}

export default App;
