import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { ZustandLocalStorageCrypted } from "./utils";
import { getAllBasic } from "./functions/basics";
import { ChurchModel, CountryModel } from "../../models/models";

const LOCAL_DATASTORE_NAME = "missionary-basics";

type ContextProps = {
  openSideBar: boolean;
  isLoading: boolean;
  langs: Array<any>;
  countries: Array<CountryModel>;
  churchs: Array<ChurchModel>;
  departments: Array<ChurchModel>;
  getAll: () => Promise<any>;
  updateSidebarOpenState: (state: boolean) => void;
};

export const useBasicsStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      openSideBar: true,
      isLoading: false,
      langs: [],
      missions: [],
      countries: [],
      churchs: [],
      departments: [],
      getAll: async () => {
        set(
          produce((state: ContextProps) => {
            state.isLoading = true;
          })
        );
        const res = await getAllBasic();
        const data = res?.data;
        set(
          produce((state: ContextProps) => {
            state.langs = data?.langs ?? [];
            state.countries = data?.countries ?? [];
            state.churchs = data?.churchs ?? [];
            state.departments = data?.departments ?? [];
            state.isLoading = false;
          })
        );
        return data;
      },
      updateSidebarOpenState: (bool = true) => {
        set(
          produce((state: ContextProps) => {
            state.openSideBar = bool;
          })
        );
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_bsc")),
    }
  )
);
