import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import BasicsProvider from "./contexts/basics/provider";
import { ThemeProvider } from "@mui/material/styles";
import { themeMui } from "./themes/mui";
import { ToastContainer } from "react-toastify";
import ReactQueryClient from "./configs/stores/react_query_client";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ReactQueryClient>
        <ThemeProvider theme={themeMui}>
          <BasicsProvider>
            <App />
          </BasicsProvider>
          <ToastContainer />
        </ThemeProvider>
      </ReactQueryClient>
    </BrowserRouter>
  </React.StrictMode>
);
