import clsx from "clsx";
import React from "react";

type Props = {
  isLoading: boolean;
  title: string;
};

const SubmitForm = ({ isLoading, title }: Props) => {
  return (
    <button
      disabled={isLoading}
      type={"submit"}
      className={clsx(
        "flex justify-center mt-2 items-center rounded-[8px] h-[50px] transition-all duration-150",
        !isLoading
          ? " bg-[#009ADE] hover:bg-hover active:bg-active"
          : "bg-buttonInactiveColor"
      )}
    >
      <span
        className={clsx(
          "flex select-none",
          !isLoading ? "text-[18px] font-bold text-white " : "text-[15px] font-semibold text-white/80"
        )}
      >
        {!isLoading ? title : "Patienter un instant..."}
      </span>
    </button>
  );
};
export default SubmitForm;
