import { Outlet } from "react-router-dom";

const ContentLayout = () => {
  return (
    <div className="flex flex-1 flex-col">
      <div className="flex h-[60px] min-h-[60px]" />
        <div className="flex flex-1 flex-col px-10 phone:px-5 tablet_mini:px-6 w-full overflow-y-auto">
          <div className="flex w-full h-auto max-w-[1532px] my-auto mx-auto">
            <Outlet />
          </div>
        </div>
    </div>
  );
};

export default ContentLayout;
