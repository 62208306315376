import axios from "axios";
import { getUrl } from "../../apiRequest";

export function getAllBasic() {
  return getUrl("/all_basics?lists=countries,churchs,departments");
}

export function getDefaultDatas() {
  return getUrl("/default/all");
}

export function getAllLangs() {
  return getUrl("/default/langs");
}

export function getDestinationFavourites(country?: string | null) {
  return getUrl("/destination/favourites");
}

export function getDestinationCapitals() {
  return getUrl("/destination/capitals");
}

export function getUserCountry() {
  return new Promise<any>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        axios
          .get(
            `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
          )
          .then((reponse) => {
            resolve(reponse.data);
          });
      },
      (error) => {
        // error.
        // console.error(error);
      },
      {
        enableHighAccuracy: true,
      }
    );
  });
}
