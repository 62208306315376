import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import {
  registration,
} from "./functions/auth";
import { ZustandLocalStorageCrypted } from "./utils";
import { UserModel } from "../../models/models";

const LOCAL_DATASTORE_NAME = "missionary-auth";

type ContextProps = {
  user: UserModel | null;
  isLoadingSignUp: boolean;
  registration: (credentials: any) => Promise<{
    [x: string]: any;
    success: Boolean;
    message: String;
    data: any;
  }>;
};

export const useAuthStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      user: null,
      isLoadingSignUp: false,
      registration: async (credentials) => {
        set(
          produce((state: ContextProps) => {
            state.isLoadingSignUp = true;
          })
        );
        const res = await registration(credentials);
        const { success = false, data = null } = res;
        set(
          produce((state: ContextProps) => {
            if (success) {
              state.user = data;
            }
          })
        );
        return res;
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_ath")),
    }
  )
);
