import React from "react";
import OfflineHeader from "./offline";

const Header = () => {
  return (
    <React.Fragment>
      <OfflineHeader />
    </React.Fragment>
  );
};

export default Header;
