import React, { ReactNode } from "react";
import { QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { ZustandLocalStorageCrypted } from "./utils";

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const persister = createSyncStoragePersister({
  storage: ZustandLocalStorageCrypted("dat_qry"),
});

type Props = {
  children: ReactNode;
};

const ReactQueryClient = ({ children }: Props) => {
  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}
    >
      {children}
      <ReactQueryDevtools
        initialIsOpen={false}
        buttonPosition={"bottom-left"}
        position={"left"}
      />
    </PersistQueryClientProvider>
  );
};

export default ReactQueryClient;
