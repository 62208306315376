import React from "react";

type Props = {
  title: string;
  error?: boolean;
};

const EmailInputForm = ({ title = "", error = false }: Props) => {
  return (
    <div className="flex flex-col w-full gap-2">
      <span className={`flex text-[16px] ${error ? "text-red-500" : ""}`}>
        {title}
      </span>
      <input
        type="email"
        name="email"
        placeholder={title}
        className={` transition-all duration-150 flex outline-4 h-[50px] outline-primary px-4 min-w-0 text-[18px] w-full bg-slate-200 rounded-[8px] ${
          error
            ? "placeholder-red-500 border-[3px] border-red-500 outline-transparent outline-none"
            : ""
        }`}
      />
    </div>
  );
};

export default EmailInputForm;
