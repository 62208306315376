import { api } from "./api";

export async function getUrl(
  url: string,
  config: any = {},
  cb = function (reponse: any) {}
): Promise<{
  success: Boolean;
  message: String;
  data: any;
  [x: string]: any;
}> {
  let reponse;
  try {
    reponse = await api.get(url, { ...config });
  } catch (error) {}
  // const reponse = await api.get(url, { ...config });
  let result = !!!reponse?.data
    ? { success: false, message: "Requete invalide !", data: null }
    : reponse.data;
  cb(result);
  return result;
}

export async function deleteUrl(
  url: string,
  config: any = {},
  cb = function (reponse: any) {}
): Promise<{
  success: Boolean;
  message: String;
  data: any;
  [x: string]: any;
}> {
  let reponse;
  try {
    reponse = await api.delete(url);
  } catch (error) {}
  // const reponse = await api.delete(url);
  let result = !!!reponse?.data
    ? { success: false, message: "Requete invalide !", data: null }
    : reponse.data;
  cb(result);
  return result;
}

export async function postUrl(
  url: string,
  body = {},
  config = {},
  cb = function (reponse: any) {}
): Promise<{
  success: Boolean;
  message: String;
  data: any;
  [x: string]: any;
}> {
  let reponse;
  try {
    reponse = await api.post(url, body);
  } catch (error) {}
  let result = !!!reponse?.data
    ? { success: false, message: "Requete invalide !", data: null }
    : reponse.data;
  cb(result);
  return result;
}

export const request = {
  getUrl,
  deleteUrl,
  postUrl,
};
