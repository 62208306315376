import axios from "axios";

export const onlineApisUrl = "https://apimissionnaire.messagesdevie.fr";//"https://missionnairemdvie-api.vercel.app";

export const localApisUrl = window.location.hostname.includes("missionnaire")
  ? onlineApisUrl
  : "http://" + window.location.hostname + ":4501";

export const usedBaseUrl = localApisUrl;

export const api = axios.create({
  baseURL: usedBaseUrl + "/v1/missionary",
});

