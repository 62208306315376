import CryptoJS from "crypto-js";

// Fonction de chiffrement
export function encrypt(data, key, iv) {
  try {
    const cipherText = CryptoJS.AES.encrypt(JSON.stringify({ data }), key, {
      iv: iv ?? undefined,
    }).toString();
    return cipherText;
  } catch (error) {
    console.error("Erreur lors du chiffrement :", error);
    throw error;
  }
}

// Fonction de déchiffrement
export function decrypt(cipherText, key, iv) {
  try {
    const bytes = CryptoJS.AES.decrypt(cipherText, key, {
      iv: iv ?? undefined,
    });
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedText)?.data;
  } catch (error) {
    console.error("Erreur lors du déchiffrement :", error);
    throw error;
  }
}
