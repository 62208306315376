import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useBasicsStore } from "../../configs/stores/basics";

const BasicsProvider = ({ children = <div /> }) => {
  const { getAll } = useBasicsStore();
  useQuery({
    enabled: true,
    queryFn: getAll,
    queryKey: ["basics"],
    staleTime: 2 * 60 * 1000, // 2 minutes in milliseconds
  });
  return <React.Fragment>{children}</React.Fragment>;
};

export default BasicsProvider;
